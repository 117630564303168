// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://qa-admin.kapsciti.com/api',
  version: 'v0.1.8-qa',
  // Los tiempos corresponden a los niveles, el primer valor es el basico, segundo el avanzado y el tercero superior
  time:[
    800,
    700,
    600
  ],
  // Juego Figuras
  range_time_game: 60,
  interval_show_figure:[
    500,
    350,
    250
  ]
};
