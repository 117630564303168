import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-instrucciones-calculo-mental',
  templateUrl: './instrucciones-calculo-mental.page.html',
  styleUrls: ['./instrucciones-calculo-mental.page.scss'],
})
export class InstruccionesCalculoMentalPage implements OnInit {
  private timer: any; // Almacena la referencia al temporizador

  constructor(private router: Router) { }

  ngOnInit() {
    this.startTimer();
    let user = JSON.parse(localStorage.getItem('user'));
    if(!user){
      this.router.navigate(['/'])
          localStorage.clear();
    }
  }
  ngOnDestroy() {
    // Asegurarse de limpiar el temporizador cuando el componente se destruya
    clearTimeout(this.timer);
  }
  startTimer() {
    this.timer = setTimeout(() => {
      // Redirige solo si el componente aún está montado y el usuario no ha navegado manualmente
      
        this.router.navigate(['/calculo-mental']);

    }, 10000); // 10 segundos
  }

  onContinue() {
    // Limpiar el temporizador para prevenir la redirección automática
    clearTimeout(this.timer);
    // Navegar manualmente
    this.router.navigate(['/calculo-mental']);
  }

}
