import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-memoriza-contrasena',
  templateUrl: './memoriza-contrasena.page.html',
  styleUrls: ['./memoriza-contrasena.page.scss'],
})
export class MemorizaContrasenaPage implements OnInit {
  password: any
  private timer: any; // Almacena la referencia al temporizador
  constructor(private router: Router) { }

  ngOnInit() {
    this.startTimer();
    let user = JSON.parse(localStorage.getItem('user'));
    if(user){
      this.password = localStorage.getItem("password_game");
    }else{
      this.router.navigate(['/'])
          localStorage.clear();
    }
  }

  /* makeid(level) {
    var result = '';
    if(level == 1){
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      var length = 5;
    }
    if(level == 2){
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      var length = 7;
    }
    if(level == 3){
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%&*()_-+=,.[]:';
      var length = 10;
    }
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  } */

  ngOnDestroy() {
    // Asegurarse de limpiar el temporizador cuando el componente se destruya
    clearTimeout(this.timer);
  }
  startTimer() {
    this.timer = setTimeout(() => {
      // Redirige solo si el componente aún está montado y el usuario no ha navegado manualmente
      
        this.router.navigate(['/memoriza-simbolo']);

    }, 10000); // 10 segundos
  }

  onContinue() {
    // Limpiar el temporizador para prevenir la redirección automática
    clearTimeout(this.timer);
    // Navegar manualmente
    this.router.navigate(['/memoriza-simbolo']);
  }

}
