import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MemorizaSimboloPageRoutingModule } from './memoriza-simbolo-routing.module';

import { MemorizaSimboloPage } from './memoriza-simbolo.page';
import { CameraVideoModule } from '../camera-video/camera-video.module';
import { ModalFigurasModule } from '../modal-figuras/modal-figuras.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MemorizaSimboloPageRoutingModule,
    CameraVideoModule,
    ModalFigurasModule
  ],
  declarations: [MemorizaSimboloPage]
})
export class MemorizaSimboloPageModule {}
