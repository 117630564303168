import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {IonInput, ToastController} from '@ionic/angular';

import {LoginService} from '../services/login.service';
import {GamesService} from '../services/games.service';
import {environment} from '../../environments/environment';
import _ from 'lodash';

@Component({
  selector: 'app-login',
  templateUrl: 'login.page.html',
  styleUrls: ['login.page.scss']
})
export class LoginPage implements OnInit,AfterViewInit {
  @ViewChild('inputUsername', {  static: false }) usernameInput: IonInput;

  username: any;
  errorCodigo = false;
  errorApi = false;
  errorEnable = false;
  version: any;
  numberArray = [];
  totalFiguresArray: any;
  figuresArray2: any;
  position: any;
  range_time_game: any;

  constructor(
    private router: Router,
    private loginSevice: LoginService,
    private gamesService: GamesService,
    public toastController: ToastController,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {

    /* if(localStorage.getItem('user') && localStorage.getItem('token')){
      this.router.navigate(['/politica-datos'])
    } */
    localStorage.clear();
    this.version = environment.version;
    this.range_time_game = environment.range_time_game;
    //for (let index = 0; index < this.range_time_game; index++) {
    for (let index = 0; index < this.range_time_game; index++) {
      this.numberArray.push(index + 1);
    }

  }

  ngAfterViewInit() {
    setTimeout(()=>{
      this.usernameInput.setFocus();
    }, 800);
  }

  ionViewWillEnter(){
    this.username = '';
  }

  onSubmit() {
    const userName = this.username;
    localStorage.clear();
    this.loginSevice.loginCode(userName).subscribe(
      (res) => {
        if (res.success) {

          this.errorApi = false;
          this.errorCodigo = false;
          this.errorEnable = false;
          // console.log(res)
          const user: any = res.user;
          localStorage.setItem('token', res.token);
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('mental_speed_id', res.mental_speed_id);
          localStorage.setItem('game_header_id', res.game_header_id);
          localStorage.setItem('mental_calculation_id', res.mental_calculation_id);
          localStorage.setItem('word_game_id', res.word_game_id);
          localStorage.setItem('letter_selected', res.letter);
          localStorage.setItem('order_rapidez_mental', res.sequence);
          localStorage.setItem('password_game', res.password);
          localStorage.setItem('figure_game', res.figure);
          localStorage.setItem('color_figure_game', res.color);
          localStorage.setItem('memorize_id', res.memorize_id);
          localStorage.setItem('memorize_figure_id', res.memorize_figure_id);
          localStorage.setItem('number_array', JSON.stringify(this.numberArray));
          localStorage.setItem('number_interval_array', '0');
          localStorage.setItem('mental_calculation_array', JSON.stringify(res.mental_calculation_array));
          localStorage.setItem('mental_calculation_result', res.mental_calculation_result);
          const reslogin = res;
          this.gamesService.getParameters(reslogin.word_game_id).subscribe(
            (res) => {
              // console.log(res)
              localStorage.setItem('words_array', JSON.stringify(res.words_array));

              this.position = ['position-figure1', 'position-figure2', 'position-figure3', 'position-figure4', 'position-figure5', 'position-figure6'];
              this.figuresArray2 = res.figures_colors;
              this.totalFiguresArray = this.figuresArray2.length;
              for (let j = 0; j < this.totalFiguresArray; j++) {
                const indexNumber = Math.floor(Math.random() * this.numberArray.length);
                this.figuresArray2[j].position = this.position[Math.floor(Math.random() * this.position.length)];
                this.figuresArray2[j].time = this.numberArray[indexNumber];
                this.numberArray.splice(indexNumber, 1);
              }

              localStorage.setItem('figures_colors_array', JSON.stringify(_.sortBy(this.figuresArray2, ['time'])));

              if (reslogin.user.data_protection === 0) {
                this.router.navigate(['/politica-datos']);
              } else {
                this.router.navigate(['/enmarcar']);
              }
            },
            (err) => {
              if (err.status === 401) {
                this.errorCodigo = true;
                this.errorApi = false;
              } else {
                this.errorCodigo = false;
                this.errorApi = true;
              }
            }
          );
        } else {
          this.errorApi = false;
          this.errorCodigo = true;
        }
        // this.router.navigate(['/politica-datos'])
      },
      (err) => {
        switch (err.status) {
          case 401:
            this.errorCodigo = true;
            this.errorApi = false;
            this.errorEnable = false;
            break;
          case 402:
            this.errorEnable = true;
            this.errorCodigo = false;
            this.errorApi = false;
            break;
          case 400:
            this.errorApi = true;
            this.errorEnable = false;
            this.errorCodigo = false;
            break;
        }
      }
    );


  }
}
