import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { $ } from 'protractor';

import { GamesService } from '../services/games.service';
import { VideoService } from '../services/video.service';

@Component({
  selector: 'app-memoriza-simbolo',
  templateUrl: './memoriza-simbolo.page.html',
  styleUrls: ['./memoriza-simbolo.page.scss'],
})
export class MemorizaSimboloPage implements OnInit, OnDestroy {
  figure: any;
  color: any;
  figure_translate: any;
  color_translate: any;
  password: any;
  user_id: any;
  text1: string;
  private timer: any; // Almacena la referencia al temporizador
  constructor(
    private router: Router,
    private gamesService: GamesService,
    private videoService: VideoService,
    public toastController: ToastController
  ) { }
  ngOnInit() {
    this.startTimer();
    let user = JSON.parse(localStorage.getItem('user'));
    if(user){

      this.figure = localStorage.getItem('figure_game');
      if(this.figure == 'circle'){
        this.figure_translate = 'circulos'
      }
      if(this.figure == 'triangle'){
        this.figure_translate = 'triangulos'
      }
      if(this.figure == 'square'){
        this.figure_translate = 'cuadrados'
      }
      if(this.figure == 'cross'){
        this.text1 = "todas las";
        this.figure_translate = 'cruces'
      }

      this.color = localStorage.getItem("color_figure_game");
      if(this.color == 'lilac'){
        this.color_translate = 'lilas'
      }
      if(this.color == 'blue'){
        this.color_translate = 'azules'
      }
      if(this.color == 'red'){
        if(this.figure == 'cross'){
          this.color_translate = 'rojas'
        }else{
          this.color_translate = 'rojos'
        }
      }
      if(this.color == 'white'){
        if(this.figure == 'cross'){
          this.color_translate = 'blancas'
        }else{
          this.color_translate = 'blancos'
        }
      }

      this.password = localStorage.getItem('password_game')
      this.user_id = user.id
    }else{
      this.router.navigate(['/'])
          localStorage.clear();
    }
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: 'bottom',
      color: "danger"
    });
    toast.present();
  }
  ngOnDestroy() {
    // Asegurarse de limpiar el temporizador cuando el componente se destruya
    clearTimeout(this.timer);
  }
  startTimer() {
    this.timer = setTimeout(() => {
      // Redirige solo si el componente aún está montado y el usuario no ha navegado manualmente

        this.router.navigate(['/instrucciones-rapidez-mental']);

    }, 10000); // 10 segundos
  }


  onContinue(){
    clearTimeout(this.timer);
    this.router.navigate(['/instrucciones-rapidez-mental']);
  }
}
