import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Plugins
} from '@capacitor/core';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Storage } from '@capacitor/storage';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
 
@Injectable({
  providedIn: 'root'
})
export class VideoService {
  public videos = [];
  private VIDEOS_KEY: string = 'videos';
 
  constructor(private httpClient: HttpClient) {}
 
  async loadVideos() {
    const videoList = await Storage.get({ key: this.VIDEOS_KEY });
    this.videos = JSON.parse(videoList.value) || [];
    return this.videos;
  }
 
  async storeVideo(blob) {
    const fileName = new Date().getTime() + '.mp4';
 
    const base64Data = await this.convertBlobToBase64(blob) as string;
    const savedFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Data
    });
 
    // Add file to local array
    this.videos.unshift(savedFile.uri);
 
    // Write information to storage
    return Storage.set({
      key: this.VIDEOS_KEY,
      value: JSON.stringify(this.videos)
    });
  }
 
  // Helper function
  private convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
 
  // Load video as base64 from url
  async getVideoUrl(fullPath) {
    const path = fullPath.substr(fullPath.lastIndexOf('/') + 1);
    const file = await Filesystem.readFile({
      path: path,
      directory: Directory.Data
    });
    return `data:video/mp4;base64,${file.data}`;
  }

  setVideosS3(videos: any, game_header_id: number): Observable<Record<string, any>> {
    const token = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: "Bearer " + token
      })
    };
    return this.httpClient
        .post(`${environment.apiUrl}/videos-store`, {videos: videos, game_header_id:game_header_id}, httpOptions)
        .pipe((result) => {
            return result;
        });
  }
}