import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EnmarcarPage } from './enmarcar/enmarcar.page';
import { InstruccionesPage } from './instrucciones/instrucciones.page';
import { LoginPage } from './login/login.page';
import { PoliticaDatosPage } from './politica-datos/politica-datos.page';
import { LoginPageModule } from './login/login.module';
import { EnmarcarPageModule } from './enmarcar/enmarcar.module';
import { InstruccionesPageModule } from './instrucciones/instrucciones.module';
import { InstruccionesCalculoMentalPageModule } from './instrucciones-calculo-mental/instrucciones-calculo-mental.module';
import { PoliticaDatosPageModule } from './politica-datos/politica-datos.module';
import { MemorizaContrasenaPageModule } from './memoriza-contrasena/memoriza-contrasena.module';
import { MemorizaSimboloPageModule } from './memoriza-simbolo/memoriza-simbolo.module';
import { AuthService } from './auth/auth.service';
import { AuthGuardService } from './auth/auth-guard.service';
import { enterAnimation } from './animations/nav-animations';
import { ModalFigurasModule } from './modal-figuras/modal-figuras.module';

@NgModule({
  declarations: [AppComponent ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      navAnimation: enterAnimation,
      mode: 'md'
    }),
    AppRoutingModule,
    HttpClientModule,
    LoginPageModule,
    EnmarcarPageModule,
    InstruccionesPageModule,
    PoliticaDatosPageModule,
    MemorizaContrasenaPageModule,
    MemorizaSimboloPageModule,
    InstruccionesCalculoMentalPageModule,
    ModalFigurasModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },HttpClientModule, AuthService, AuthGuardService],
  bootstrap: [AppComponent],
})
export class AppModule {}
