import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ModalFigurasComponent } from './modal-figuras.component';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
  ],
  declarations: [ModalFigurasComponent],
  exports:[
    ModalFigurasComponent
  ]
})

export class ModalFigurasModule {}
