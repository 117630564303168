import { Injectable } from '@angular/core';
import {
  Plugins
} from '@capacitor/core';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Storage } from '@capacitor/storage';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public videos = [];
  private VIDEOS_KEY: string = 'videos';
 
  constructor(private httpClient: HttpClient) {}
 
  getToken(): Observable<Record<string, any>> {
    return this.httpClient
        .post(`${environment.apiUrl}/login_check`, {username: "asotillo",password: "12345678"})
        .pipe((result) => {
            return result;
        });
  }
  loginCode(code: string): Observable<Record<string, any>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.httpClient
        .post(`${environment.apiUrl}/auth/login`, {code: code}, httpOptions)
        .pipe((result) => {
            return result;
        });
  }
  data_protection(user_id: number, token: string): Observable<Record<string, any>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: "Bearer " + token
      })
    };
    return this.httpClient
        .post(`${environment.apiUrl}/set-data-protection`, {user_id: user_id}, httpOptions)
        .pipe((result) => {
            return result;
        });
  }
}